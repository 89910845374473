

body {
  margin: 0;
  font-family: 'Manrope', 'Helvetica Neue', sans-serif;
  color: #EEE;
  --bs-body-color: #EEE;
  --bs-body-bg: #444;
  --bs-link-color: rgb(116, 116, 248);
  --bs-link-hover-color: rgb(71, 71, 247);

}

.top-margin { margin-top: 24px; }

.card {
  --bs-card-bg: #444;
  --bs-card-color: #EEE;
  --bs-card-border-color: #444;
  --bs-card-spacer-y: 2px;
}

.table .invalid td { color: #777 !important; }

h1, h2 { font-weight: 700; }
.main-content { margin-top: 70px; background-color: #444; color: #ddd; min-height: 400px;}
.form-group{ margin-top: 8px; }
.narrow-form { margin-top: 8px; margin-bottom: 8px; }
.form-outline { border: solid 1px #666; padding: 8px 16px; border-radius: 8px; }
.field-errors { font-size: 80%; color: #dc3545; }
.form-control { background: #CCC; }
a { text-decoration: none; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alert { --bs-alert-border-color: #202020; }
.alert-success { --bs-alert-bg: #9ce4c4; }
.alert-info { --bs-alert-bg: rgb(33, 37, 41); border-color: rgb(102, 102, 102); color: #EEE; }
.alert-danger { --bs-alert-bg: #f17070; }
.alert .title { font-weight: bold; margin-bottom: 0.75em; }
.icon-info { color: #0EB36E }

.icon-valid { color: #4C4; }
.icon-invalid { color: #F44; }

.product-table img { margin-bottom: 8px;}
.product-table .row { margin-bottom:16px; }
.product-table .title { font-weight: 700; font-size: 140%; }
.product-table .product-version { color: #A0A0A0; }
.order-big { font-size: 150%; }

.breadcrumb2-holder {
	background: #555;
}

.breadcrumb2 {
	padding: 8px 0px;
	overflow: hidden;
}
.breadcrumb2 ol {
	list-style: none;
	overflow: hidden;
	padding: 0;
	padding-right: 30px;
	margin-bottom: -1px; /* to help with wrapping */
}

.breadcrumb2 li {
	float: left;
}
.breadcrumb2 li a {
	color: white;
	text-decoration: none;
	padding: 0px 0 0px 30px;
	background: brown;                   /* fallback color */
	background: hsla(35,71%,45%,1);
	position: relative;
	display: block;
	float: left;
}

.breadcrumb2 li a:after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 20px solid transparent;           /* Go big on the size, and let overflow hide */
	border-bottom: 20px solid transparent;
	border-left: 20px solid hsla(35,71%,45%,1);
	position: absolute;
	top: 50%;
	margin-top: -20px;
	left: 100%;
	z-index: 2;
}

.breadcrumb2 li a:before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 20px solid transparent;
	border-bottom: 20px solid transparent;
	border-left: 20px solid #666;
	position: absolute;
	top: 50%;
	margin-top: -20px;
	margin-left: 2px;
	left: 100%;
	z-index: 1;
}

.breadcrumb2 li:first-child a {
	padding-left: 13px;
}

.breadcrumb2 li:nth-child(1) a       { background:        #335544; }
.breadcrumb2 li:nth-child(1) a:after { border-left-color: #335544; }
.breadcrumb2 li:nth-child(2) a       { background:        #218459; }
.breadcrumb2 li:nth-child(2) a:after { border-left-color: #218459; }
.breadcrumb2 li:nth-child(3) a       { background:        #0EB36E; }
.breadcrumb2 li:nth-child(3) a:after { border-left-color: #0EB36E }




.breadcrumb2 li a:hover { background: #10C979;  }
.breadcrumb2 li a:hover:after { border-left-color: #10C979 !important; }

.breadcrumb2 span { padding-left: 30px; font-weight: bold; color: white; }

.top-nav-short { padding: 0.1rem;}
.top-nav-short img { width: 100px; }

.header { padding: 1em 0; background-origin: padding-box; background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background: url('/img/tsuga7945.jpg') ;background-position: center; }
.header h1 { font-weight: bold; color: #FFF; }
.dropshadow { -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.9));
	filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.9)); }